'use strict';

var decodeBase64URI = function (context) {
    var attribute = context.getAttribute('data-cbo');
    return attribute ? decodeURIComponent(window.atob(attribute)) : '';
};

var handleCBOClick = function (event) {
    if (event.ctrlKey || event.metaKey || $(this).attr('target') === '_blank') {
        var newWindow = window.open(decodeBase64URI(this), '_blank');
        newWindow.focus();
    } else {
        document.location.href = decodeBase64URI(this);
    }
};

var handleCBOAjaxClick = function () {
    if (!$(this).hasClass('product-detail-image-modal')) {
        $(this).attr('href', decodeBase64URI(this));
    }
};

var handleCBOSpan = function (e) {
    e.stopPropagation();
    var encodedUrl = $(this).attr('data-cbo');
    var showMoreUrl = decodeURIComponent(window.atob(encodedUrl));
    window.location.href = showMoreUrl;
};

module.exports = function () {
    $('body').on('click', '.cbo', handleCBOClick);
    $('body').on('click', '.ajax-cbo', handleCBOAjaxClick);
    $('body').on('click', '.link-cbo', handleCBOSpan);
};
