'use strict';

var $headerBanner = $('.header-banner');
var $navigation = $('.navigation');
var isMobile = window.app.isMobile();
const recommendationsLogic = require('./components/recommendations');

$(window).on('load', function () {
    var $einsteinRecommendations = $('.einstein-recommendation');
    if ($einsteinRecommendations.length && !$einsteinRecommendations.find('.slick-initialized').length) {
        var interval = setInterval(function () {
            if ($einsteinRecommendations.find('.product-grid').length) {
                $einsteinRecommendations.find('.product-grid').each(function () {
                    recommendationsLogic.initRecommandationLogic();
                    recommendationsLogic.obfuscationEinstein();
                    clearInterval(interval);
                });
            }
        }, 200);
    }
});

$(document).ready(function () {
    var $productShow = $('.product-show');
    var searchInput = $('.general-search-input');
    var firstPlaceholder = searchInput.data('placeholder1');
    var secondPlaceholder = searchInput.data('placeholder2');

    /**
     * Update the sticky-top class on the navigation element based on scroll position and other conditions.
     * @function updateStickyTop
     */
    function updateStickyTop() {
        var isScrollPastHeader = $(window).scrollTop() > $headerBanner.height();
        var placeholder = isScrollPastHeader ? secondPlaceholder : firstPlaceholder;

        $navigation.toggleClass('sticky-top', isScrollPastHeader);
        searchInput.attr('placeholder', placeholder);
    }

    if (isMobile) {
        if ($productShow.length) {
            $navigation.addClass('sticky-top');
            searchInput.attr('placeholder', secondPlaceholder);
        } else {
            // Call on scroll
            $(window).on('scroll', function () {
                updateStickyTop();
            });
        }
    }
});

// eslint-disable-next-line no-useless-escape
var pattern = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
var errorMessage = $('.footer-newsletter .email-error');

$(document).on('click', '.js-email-validation', function () {
    var userEmail = $(document).find('.js-email').val();
    if (!pattern.test(userEmail) || userEmail.length === 0) {
        errorMessage.removeClass('d-none');
    } else {
        errorMessage.addClass('d-none');
        var encodeUrl = $('.js-email').data('href');
        encodeUrl = encodeUrl + '?email=' + userEmail;
        window.location.href = encodeUrl;
    }
});

$(document).on('click', '.country-selector-selectbox', function () {
    $(this).toggleClass('active');
    $('.country-selector-list-items').toggle();
});

$(document).on('click', '.country-selector-list-items a', function (e) {
    // Prevent infinite loop
    $(this).unbind('click');

    // Execute default action
    e.currentTarget.click();
});
