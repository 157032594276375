'use strict';

const CS = require('./contentSquare');

const isDataLayerDefined = () => {
    return typeof dataLayer !== 'undefined';
};

const pushToDataLayer = (data) => {
    if (!isDataLayerDefined() || !data) {
        return;
    }
    dataLayer.push(data);
};

const getTilePosition = (e) => {
    var imageIndex;
    var linkIndex;

    var productRecommandation = e.closest('.product-recommendations');

    if (productRecommandation.length > 0) {
        imageIndex = productRecommandation.find('.product-tile > .image-container').index(e) + 1;
        linkIndex = productRecommandation.find('.link').index(e) / 2 + 1;
    } else {
        imageIndex = $('.product-tile > .image-container').index(e) + 1;
        linkIndex = $('.link').index(e) / 2 + 1;
    }

    return Math.max(imageIndex, linkIndex);
};

const handleClickEvents = () => {
    $('body').on('product:afterAddToCart', (e, data) => {
        if (data.DLEvents && Array.isArray(data.DLEvents)) {
            data.DLEvents.forEach(DLEvent => {
                pushToDataLayer(DLEvent);
            });
        }
    });

    $('body').on('afterRemoveFromCart', function (e, data) {
        if (data.DLEvents && Array.isArray(data.DLEvents)) {
            data.DLEvents.forEach(DLEvent => {
                pushToDataLayer(DLEvent);
            });
        }
    });

    $('body').on('cart:update', function (e, data) {
        if (data.DLEvents && Array.isArray(data.DLEvents)) {
            data.DLEvents.forEach(DLEvent => {
                pushToDataLayer(DLEvent);
            });
        }
    });

    // Checkout - Step 1
    $('#checkoutBegin .checkout-btn').click(function (e) {
        e.preventDefault();
        var price = $('.grand-total')[0].innerText.split(' ')[0].replace(/,/g, '.');
        var products = $('.product-info').map(function () {
            return $(this).data('product-gtm');
        });
        var productsGTM = {
            event: window.GTMConstants.EVENT_TYPE_CHECKOUT,
            panier: parseFloat(price),
            ecommerce: {
                checkout: {
                    actionField: { step: 1 },
                    products: getProductsData(products)
                }
            }
        };
        pushToDataLayer(productsGTM);
        // eslint-disable-next-line no-restricted-globals
        location.href = $(this).attr('href');
    });

    // Checkout - Step 3
    $('body').on('checkout:shipping', function () {
        pushToDataLayer({
            event: window.GTMConstants.EVENT_TYPE_CHECKOUT,
            ecommerce: {
                checkout: {
                    actionField: {
                        step: 3
                    }
                }
            }
        });
        CS.handleContentSquareEvents();
    });

    // Checkout Option - Step 2
    $('body').on('checkout:account', function (e, data) {
        pushToDataLayer({
            event: window.GTMConstants.EVENT_TYPE_CHECKOUT_OPTION,
            ecommerce: {
                checkout: {
                    actionField: {
                        step: 2,
                        option: data.option
                    }
                }
            }
        });
    });

    // Checkout Options Step 3 - Checkout Step 4
    $('body').on('checkout:shippingDone', function (e, data) {
        pushToDataLayer({
            event: window.GTMConstants.EVENT_TYPE_CHECKOUT,
            ecommerce: {
                checkout: {
                    actionField: {
                        step: 4
                    }
                }
            }
        });
        pushToDataLayer({
            event: window.GTMConstants.EVENT_TYPE_CHECKOUT_OPTION,
            ecommerce: {
                checkout: {
                    actionField: {
                        step: 3,
                        option: data.shippingMethod ? data.shippingMethod : ''
                    }
                }
            }
        });
        CS.handleContentSquareEvents();
    });

    $('body').on('newsletter:subscription', function (e, data) {
        if (data.DLEvents && Array.isArray(data.DLEvents)) {
            data.DLEvents.forEach(DLEvent => {
                pushToDataLayer(DLEvent);
            });
        }
    });

    $('body').on('wishlist:addItem', function (e, data) {
        if (data.DLEvents && Array.isArray(data.DLEvents)) {
            data.DLEvents.forEach(DLEvent => {
                pushToDataLayer(DLEvent);
            });
        }
    });

    // Product Click
    $('body').on('click', '.product-tile .image-container, .product-tile .link-primary', function (e) {
        e.preventDefault();

        var productTile = $(this).closest('.product-tile')[0];
        var $productTile = $(productTile);

        if (productTile.hasAttribute('data-product-gtm') && $productTile.data('product-gtm') !== null) {
            var productGTM = $productTile.data('product-gtm');

            var price = productGTM.ecommerce.detail.products ? productGTM.ecommerce.detail.products[0].price : '0';
            productGTM.ecommerce.detail.products[0].price = parseFloat(price);
            productGTM.ecommerce.detail.products[0].position = getTilePosition($(this));
            productGTM.event = window.EVENT_TYPE_PRODUCT_CLICK;
            pushToDataLayer(productGTM);
        } else {
            var customProductGTMObj = {};
            var customProductObj = {};

            customProductObj.id = $productTile.parent('.product').attr('data-pid');
            customProductObj.name = $productTile.find('.tile-Infos .link-primary').text();
            customProductObj.brand = $productTile.find('.tile-Infos .brand-name').text();
            customProductObj.position = ($productTile.closest('.product-grid-region').attr('data-position') * 1) + 1;

            if ($productTile.find('.tile-Infos > .price > .range').length > 0) {
                customProductObj.price = parseFloat($productTile.find('.tile-Infos > .price > .range .standard-list-pricing').attr('content'));
            } else {
                customProductObj.price = parseFloat($productTile.find('.tile-Infos > .price .standard-pricing .standard-list .value').attr('content'));
            }

            customProductGTMObj.event = window.GTMConstants.EVENT_TYPE_PRODUCT_CLICK;
            customProductGTMObj.ecommerce = {
                detail: {
                    products: [customProductObj]
                }
            };
            pushToDataLayer(customProductGTMObj);
        }

        var href;
        if ($(this).hasClass('link-primary')) { // clicked on the link
            href = $(this).attr('href');
        } else { // clicked on the image
            href = $(this).closest('.image-container').find('a').attr('href');
        }

        var productClickDL = dataLayer.find(dl => {
            return dl.event === 'productClick';
        });
        if (productClickDL) {
            // eslint-disable-next-line no-restricted-globals
            const url = new URL(href, location);
            url.searchParams.append('from', productClickDL.ecommerce.detail.actionField.list);
            href = url.toString();
        }

        // eslint-disable-next-line no-restricted-globals
        $(location).attr('href', href);
    });

    // PDP - Open filters
    $('body').on('open-popup', function () {
        pushToDataLayer({ event: window.GTMConstants.EVENT_TYPE_OPEN_FILTERS });
    });

    // PDP - Close filters
    $('body').on('close-popup', function () {
        pushToDataLayer({ event: window.GTMConstants.EVENT_TYPE_CLOSE_FILTERS });
    });
};

// get products data for Checkout Step 1
const getProductsData = (products) => {
    var productsGTM = [];
    for (var i = 0; i < products.length; i++) {
        var productVariantColour = '';
        var productVariantSize = '';
        var productVariantQuantity = '';

        // Handle special product (club card and gift card)

        // Not all the products have colour variation, for the club/gift card there is no color variation
        // Not all the products have a size variation, for the club card there is no size variation
        // Not all the products have a quantity select, for the club card the quantity is always 1
        switch (true) {
            case products[i].orc_isClubCard === true:
                productVariantColour = 'club card';
                productVariantSize = '';
                productVariantQuantity = 1;
                break;
            case products[i].orc_is_gift_card === true:
                productVariantColour = 'gift card';
                productVariantSize = '';
                productVariantQuantity = 1;
                break;
            default:
                var parsedID = products[i].id.replace('\\', '\\\\');
                if ($('#orc_color-' + parsedID)[0]) {
                    productVariantColour = $('#orc_color-' + parsedID)[0].innerText;
                }
                if ($('#orc_size-' + parsedID)[0]) {
                    productVariantSize = $('#orc_size-' + parsedID)[0].innerText;
                }
                if ($('select[data-pid=' + parsedID + ']')[0]) {
                    productVariantQuantity = $('select[data-pid=' + parsedID + ']')[0].value;
                }
        }

        productsGTM.push({
            id: products[i].masterId,
            name: products[i].productName,
            category: products[i].gtmCategory,
            variant: productVariantColour,
            brand: (products[i].brand ? products[i].brand : ''), // Not all the products have a brand set in BM at product level
            quantity: productVariantQuantity ? parseInt(productVariantQuantity, 10) : 1,
            price: products[i].price.standard.list.value,
            dimension11: productVariantSize,
            dimension12: products[i].dimension12,
            dimension13: products[i].id
        });
    }
    return productsGTM;
};

const filterEvent = (attribute, name) => {
    var data = window.dataLayer.filter(elmt => elmt[attribute] === name);
    return data.length > 0 ? data[0] : null;
};

const handleMobileTracking = () => {
    // Follow Analytics - Dom
    $(document).ready(function () {
        window.getDomData = function () {
            var domData = filterEvent('event', window.GTMConstants.EVENT_TYPE_DOM);
            return domData ? domData.data : null;
        };
    });

    // Follow Analytics - Purchase
    $('body').on('confirm:init', function () {
        window.getPurchaseData = function () {
            return filterEvent('event', window.GTMConstants.EVENT_TYPE_PURCHASE);
        };
    });

    // Follow Analytics - Cart
    $('body').on('cart:init', function () {
        window.getCartData = function () {
            return filterEvent('pagetype', window.GTMConstants.ECOMM_PAGE_TYPE_PANIER);
        };
    });

    // Follow Analytics - Cart
    $('body').on('plp:init', function () {
        window.getPLPData = function () {
            return filterEvent('event', window.GTMConstants.EVENT_TYPE_LOAD);
        };
    });
};

module.exports = {
    isDataLayerDefined,
    pushToDataLayer,
    handleClickEvents,
    handleMobileTracking
};
