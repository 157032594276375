/* eslint-disable no-underscore-dangle */

'use strict';

var _ = require('lodash');

const pushCvarUxa = (index, name, value) => {
    window._uxa = window._uxa || [];
    window._uxa.push(['setCustomVariable', index, name, value]);
};

const pushPageView = (params) => {
    window._uxa = window._uxa || [];
    window._uxa.push(['trackPageview', window.location.pathname + window.location.hash.replace('#', '?__') + params]);
};

const getDatalayerGTM = () => {
    var blacklist = ['axeptio', window.GTMConstants.EVENT_TYPE_CHECKOUT_OPTION];
    return dataLayer.reduce(function (result, currentObject) {
        if (!_.isEmpty(currentObject.event) && blacklist.indexOf(currentObject.event) < 0) {
            Object.keys(currentObject).forEach(function (key) {
                if (currentObject.hasOwnProperty(key)) {
                    result[key] = currentObject[key];
                }
            });
        }
        return result;
    });
};

const isCVarSet = (index) => {
    var cvar = window._uxa.find(x => x.at(1) === index && x.at(3) !== '');
    return typeof cvar !== 'undefined' || (cvar && cvar[3] === '');
};

const handleContentSquareEvents = () => {
    /* CUSTOM VARIABLES */
    $(document).ready(function () {
        window._uxa.push(['setCustomVariable', 6, '', '', '']);
        window._uxa.push(['setCustomVariable', 7, '', '', '']);

        var resultObject = getDatalayerGTM();
        var folder = $('.storepage').attr('folder');
        const ecommerce = _.get(resultObject, 'ecommerce', {});

        // CVAR 6 - pagetype
        if (folder === window.GTMConstants.ECOMM_PAGE_TYPE_HOMEPAGE || folder === window.GTMConstants.ECOMM_PAGE_TYPE_GUIDE) {
            pushCvarUxa(6, 'pagetype', folder);
        } else if (resultObject.pagetype) {
            pushCvarUxa(6, 'pagetype', resultObject.pagetype);
        } else if (!_.isEmpty(ecommerce) && !_.isEmpty(ecommerce.purchase) && !_.isEmpty(ecommerce.purchase.actionField)) {
            pushCvarUxa(6, 'pagetype', window.GTMConstants.EVENT_TYPE_CHECKOUT);
        }

        // CVAR 7 - categorypage
        if (!_.isEmpty(ecommerce) && !_.isEmpty(ecommerce.checkout) && !_.isEmpty(ecommerce.checkout.actionField)) {
            var step = ecommerce.checkout.actionField.step;
            var libStep = window.CSConstants.CS_CHECKOUT_STEPS[step - 1];
            pushCvarUxa(7, 'category', libStep, '');
            if (step === 3) {
                pushPageView(window.CSConstants.CS_CHECKOUT_SHIPPING);
            }
            if (step === 4) {
                pushPageView(window.CSConstants.CS_CHECKOUT_PAYMENT);
            }
        } else if (resultObject.categorypage) {
            const categorypage = resultObject.categorypage;
            if (categorypage === window.GTMConstants.ECOMM_PAGE_TYPE_PANIER) {
                pushCvarUxa(7, 'category', categorypage);
            } else if (!_.isEmpty(ecommerce) && !_.isEmpty(ecommerce.impressions)) {
                pushCvarUxa(7, 'category', categorypage);
            }
        }
    });
};

module.exports = {
    handleContentSquareEvents,
    pushCvarUxa,
    isCVarSet
};
