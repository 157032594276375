'use strict';

const tileImage = require('../utils/tileImage');

/**
* Updates the progress bar for a slider
* @param {jQuery} $parent - The parent element containing the progress bar and label
* @param {number} nextSlide - The index of the next slide
* @param {number} slideCount - The total number of slides
* @param {number} slidesToShow - The number of slides to be displayed at a time
* @returns {void}
*/
function updateProgressBar($parent, nextSlide, slideCount, slidesToShow) {
    let calc;
    let $progressBar = $parent.find('.progress');
    let $progressBarLabel = $parent.find('.slider__label');

    if (nextSlide === 0) {
        calc = 0;
    } else {
        calc = ((nextSlide + 1) / (slideCount - slidesToShow + 1)) * 100;
    }

    $progressBar.css('background-size', calc + '% 100%').attr('aria-valuenow', calc);
    $progressBarLabel.text(calc + '% completed');
}

/**
* Returns the options for the slider component
* @returns {Object} options for the slider
*/
function getSliderOptions() {
    return {
        slidesToShow: 4,
        slidesToScroll: 2,
        dots: false,
        focusOnSelect: false,
        arrows: true,
        infinite: false,
        responsive: [
            {
                breakpoint: 544,
                settings: {
                    slidesToShow: 1.5,
                    arrows: true,
                    slidesToScroll: 1
                }
            }
        ]
    };
}

/**
 * Init product recommendation slick
 */
function initProductRecommendationSlick() {
    $(document).ready(function () {
        let $slider = $('.slick-slider');
        let sliderOptions = getSliderOptions();

        $slider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            let $parent = $(this).parent();
            updateProgressBar($parent, nextSlide, slick.slideCount, slick.options.slidesToShow);
        });

        let baseOptions = getSliderOptions();

        // Modify options specific to the popup slick slider
        let popupSliderOptions = $.extend({}, baseOptions, {
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 544,
                    settings: {
                        slidesToShow: 1.5,
                        arrows: true,
                        slidesToScroll: 1
                    }
                }
            ]
        });

        // Initialize the popup slick slider
        $('.added-to-cart-popup .product-recommendations .product-grid').not('.slick-initialized').slick(popupSliderOptions);

        // Initialiser le slick slider principal
        $('.product-recommendations .product-grid').not('.slick-initialized').slick(sliderOptions);

        $('.product-recommendations .image-container').each(function (index, element) {
            tileImage.initHoverImageContainer($(element));
        });
    });
}

/**
 * Obfuscation of the href
 */
function obfuscationEinstein() {
    $('body').on('click', '.image-container .link-cbo', function (e) {
        e.stopPropagation();
        var encodedUrl = $(this).attr('data-cbo');
        var showMoreUrl = decodeURIComponent(window.atob(encodedUrl));
        var imageReplaceURL = showMoreUrl.replace('#', '%23');
        window.location.href = imageReplaceURL;
    });
}

/**
 * Init quick access feature
 */
function initRecommandationLogic() {
    $(function () {
        initProductRecommendationSlick();
        obfuscationEinstein();
    });
}

module.exports = {
    getSliderOptions, updateProgressBar, initRecommandationLogic, obfuscationEinstein, initProductRecommendationSlick
};
