'use strict';

/**
 * Init hover image different of th original image too prevent double load of an image
 * @param {DOMElement} $hoverContainer hover container
 */
function initHoverImageContainer($hoverContainer) {
    Array.prototype.forEach.call($hoverContainer.find('.tile-hover-image'), element => {
        if (element.dataset.src.endsWith('1_X.jpg')) {
            element.remove();
        }
    });
    Array.prototype.forEach.call($hoverContainer.find('.images-hover'), element => {
        if (element.children.length === 3) {
            element.children[0].className += ' let-hover';
        }
    });
}

// Hover Image
/**
 * Search to see for each element if there is an hover image different of th original image too prevent double load of an image
 */
function hoverImage() {
    $(window).ready(function () {
        let $hoverContainer = $('.image-container');
        initHoverImageContainer($hoverContainer);
    });
}

module.exports = {
    hoverImage: hoverImage,
    initHoverImageContainer: initHoverImageContainer
};
