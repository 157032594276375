'use strict';

var basicSlider = require('../utils/basicSlider');

/**
 * Init quick access feature
 */
function initQuickAccess() {
    $(document).ready(function () {
        var $categoriesContainer = $('.categories-container');
        var $currentCategory = $('.current-cat');
        var $quickAccessContainer = $('.quick-access-container');

        basicSlider.initScrollingPosition($quickAccessContainer, $categoriesContainer, $currentCategory);
        $quickAccessContainer.on('click', '.scroll-btn', function (event) {
            basicSlider.handleSrollButton(event, $categoriesContainer);
        });
    });
}

module.exports = { initQuickAccess };
