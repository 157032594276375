'use strict';

const tileImage = require('../utils/tileImage');
const { updateProgressBar } = require('./recommendations');
const { getSliderOptions } = require('./recommendations');

/**
* Initializes a slider with specified options
* @param {Element} element - the HTML element to initialize as a slider
*/
function initializeSlider(element) {
    let $slider = $(element);

    // Update progress bar based on current slide
    $slider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        let $parent = $(this).parent();
        updateProgressBar($parent, nextSlide, slick.slideCount, slick.options.slidesToShow);
    });

    let sliderOptions = getSliderOptions();

    if ($slider.closest('.added-to-cart-popup').length > 0) {
        sliderOptions.slidesToShow = 1;
        sliderOptions.slidesToScroll = 1;
        sliderOptions.responsive = [{
            breakpoint: 544,
            settings: {
                slidesToShow: 1.5,
                arrows: true,
                slidesToScroll: 1
            }
        }];
    }

    $slider.slick(sliderOptions);
    tileImage.initHoverImageContainer($slider.find('.image-container'));
}

/**
 * Einstein slot recommendations are not loaded directly on the DOM
 * Thus we need to add an observer to trigger the initialization of
 * Slick when the slot is added to the DOM.
 * As a slot can be rendered in any type of pages this script need
 * to be included in main js
 */
function initializeEinsteinSliders() {
    const observer = new MutationObserver(function (mutations) {
        mutations.forEach((mutation) => {
            if (mutation.addedNodes && mutation.addedNodes.length > 0) {
                $(mutation.addedNodes).find('.product-grid').not('.slick-initialized').each(function () {
                    initializeSlider(this);
                });
            }
        });
    });
    observer.observe(document.body, { childList: true, subtree: true });
}

module.exports = {
    initializeEinsteinSliders: initializeEinsteinSliders
};
