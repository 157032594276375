'use strict';

module.exports = {
    loadWishlistItems: function () {
        var $headerWishlistIcon = $('.header-wish-list');
        var url = null;
        var wItems = [];
        var activateButtons = function () {
            wItems.forEach(item => {
                $(`.addTowishlist[data-pid="${item.id}"] .fa-heart-icon`).removeClass('fa-heart-icon').addClass('fa-heart-icon-on');
            });
        };
        var loadAllItems = function () {
            if (!url) {
                return;
            }
            $.ajax({
                method: 'GET',
                url: url,
                success: function (data) {
                    if (!data || !data.list || !Array.isArray(data.list.items)) {
                        return;
                    }
                    // no need to concat because response contains all items (not only the requested page content)
                    wItems = data.list.items;
                    if (data.list.showMore && data.list.showMoreUrl) {
                        url = data.list.showMoreUrl;
                        loadAllItems();
                    } else {
                        // activate all items already in wishlist
                        activateButtons();
                    }
                }
            });
        };

        if ($headerWishlistIcon.length && $headerWishlistIcon.attr('data-items-url')) {
            url = $headerWishlistIcon.attr('data-items-url');
            loadAllItems();

            // observe dynamically loaded products and activate the ones added to wishlist
            var observer = new MutationObserver(function (mutations) {
                mutations.forEach(function (mutation) {
                    var newNodes = mutation.addedNodes;
                    if (newNodes !== null) {
                        var $nodes = $(newNodes);
                        $nodes.each(function () {
                            var $node = $(this);
                            if ($node.find('.addTowishlist').length > 0) {
                                activateButtons();
                            }
                        });
                    }
                });
            });

            observer.observe($('body')[0], {
                attributes: false,
                childList: true,
                subtree: true
            });
        }
    }
};
