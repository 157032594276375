'use strict';

/**
 * Display toast popup Animations
 * @param {string} popupEl popup class name
 */
function displayToastPopup(popupEl) {
    $(popupEl).removeClass('d-none');
    setTimeout(function () {
        $(popupEl).addClass('d-none');
    }, 5000);
}

module.exports = { displayToastPopup: displayToastPopup };
