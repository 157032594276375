'use strict';

const { isEmpty } = require('lodash');

/**
 *
 * @param {Object} obj object to serialize
 * @returns {string} query string
 */
function serialize(obj) {
    if (!obj) {
        return '';
    }
    var queryString = [];
    Object.keys(obj).forEach(function (key) {
        var element = obj[key];
        if (obj.hasOwnProperty(key)) {
            queryString.push(encodeURIComponent(key) + '=' + encodeURIComponent(element));
        }
    });

    return queryString.join('&');
}

/**
 * Replace $externalURL to real url
 */
function contentExternalUrl() {
    var devEnv = 'development';
    var $testingDiv = $('#testing');
    var testingEnvironment = $testingDiv.length ? $testingDiv.attr('data-testing') : null;
    var $links = $('body').find('a[href^="$externalURL"]');
    var $cbo = $('body').find('[data-cbo^="$externalURL"]');
    var applyHref = function (link, href, attr) {
        // eslint-disable-next-line no-unused-vars
        var [_, urls] = href.match(/\$externalURL\((.*)\)\$/) || '';
        if (!isEmpty(urls)) {
            var [prodUrl, stagingUrl, devUrl] = urls.split(',').map(url => url.replace(/[\s']/g, ''));
            if (testingEnvironment) {
                if (devUrl && testingEnvironment === devEnv) {
                    $(link).attr(attr, devUrl);
                } else {
                    $(link).attr(attr, stagingUrl);
                }
            } else {
                $(link).attr(attr, prodUrl);
            }
        }
    };

    if ($links.length) {
        $links.each(function (_, link) {
            applyHref(link, $(link).attr('href'), 'href');
        });
    }
    if ($cbo.length) {
        $cbo.each(function (_, link) {
            applyHref(link, $(link).attr('data-cbo'), 'data-cbo');
        });
    }
}

module.exports = { serialize, contentExternalUrl };
