'use strict';

$(document).ready(function () {
    $('.tile-slider-nav').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        focusOnSelect: true,
        arrows: false
    });
});
