'use strict';

const base = require('base:product/quickView');
const attributesLogic = require('attributes');

/**
 * Parse HTML code in Ajax response
 *
 * @param {string} html - Rendered HTML from quickview template
 * @return {QuickViewHtml} - QuickView content components
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));
    return $html.find('.product-quickview');
}

/**
 * Generates the modal window on the first call.
 *
 */
/**
* Returns the HTML element of the modal
*/
function getModalHtmlElement() {
    if ($('#quickViewModal').length !== 0) {
        $('#quickViewModal').remove();
    }
    var htmlString = ''
        + '<div class="modal fade" id="quickViewModal" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog quick-view-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span class="close-btn"></span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '</div>'
        + '</div>'
        + '</div>';

    var $modalEl = $(htmlString);
    $modalEl.on('shown.bs.modal', function () {
        // this event will be catched to be used to initialize slick slider
        $('body').trigger('quickview:ready', $(this));
    });
    $('body').append($modalEl);
}

/**
* Fills the quick view modal with the selected value
* @param {string} selectedValueUrl - URL of the selected value
* @param {Object} productGtm - GTM data of the product
*/
function fillModalElement(selectedValueUrl, productGtm) {
    $('.modal-body').spinner().start();
    $.ajax({
        url: selectedValueUrl,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            var parsedHtml = parseHtml(data.renderedTemplate);
            var $quickViewModal = $('#quickViewModal');

            $quickViewModal.find('.modal-body').empty().html(parsedHtml).find('.quick-view-carousel-area')
                .addClass('d-none');
            $('.full-pdp-link').text(data.quickViewFullDetailMsg);
            $('.full-pdp-link', $quickViewModal).attr('href', data.productUrl);
            $('.size-chart', $quickViewModal).attr('href', data.productUrl);
            $('.modal-header .close .sr-only', $quickViewModal).text(data.closeButtonText);
            $('.enter-message', $quickViewModal).text(data.enterDialogMessage);
            $quickViewModal.attr('data-product-gtm', JSON.stringify(productGtm));
            $quickViewModal.modal('show');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
* Updates the Add to Cart button's text and state
* @param {boolean} disableButton - whether to disable the button
*/
function updateAddToCartButton(disableButton) {
    const $addToCartButton = $('button.add-to-cart-global');
    $addToCartButton.attr('disabled', disableButton);

    const $section = $addToCartButton.closest('.add-to-cart-section');

    if ($section.length > 0) {
        if (disableButton) {
            $addToCartButton.text($section.attr('data-text-choose'));
        } else {
            $addToCartButton.text($section.attr('data-text-ready'));
        }
    }
}

/**
 * Init events for quickView
 */
function initQuickViewLogic() {
    // Init event on document ready
    $(function () {
        var $body = $('body');
        $body.on('click', '.product-attributes-section .attribute.toggle', attributesLogic.toggleAttribute);
        $body.on('click', '.attribute-values .circle-unselected', attributesLogic.selectAttributeValue);

        $body.on('product:updateAddToCart', function (e, response) {
            const disableButton = (!response.product.readyToOrder || !response.product.available);
            updateAddToCartButton(disableButton);
        });
        $body.on('quickview:ready', function (e, response) {
            $('.alert-incompatible-shipping-methods').html($('#incompatible-shipping-methods-message-holder').html());

            var $carousel = $();
            var $modal = $(response);

            if ($modal.length) {
                $carousel = $modal.find('.quick-view-carousel-area .detail-slider-nav.detail-desktop-images.quickview-carousel');
            } else {
                $carousel = $('.quick-view-carousel-area .detail-slider-nav.detail-desktop-images.quickview-carousel');
            }

            $carousel.on('init', function () {
                $modal.find('.quick-view-carousel-area').removeClass('d-none');
            });

            $carousel.slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                focusOnSelect: true,
                infinite: false,
                arrows: true
            });
        });
    });
}

module.exports = {
    ...base,
    ...{
        getModalHtmlElement,
        initQuickViewLogic,
        showQuickview: function () {
            $('body').on('click', '.quickview', function (e) {
                e.preventDefault();
                e.stopPropagation();
                var selectedValueUrl = $(this).attr('href');
                $(e.target).trigger('quickview:show');
                getModalHtmlElement();
                var $productGtm = $($(this).closest('.product-tile')[0]).data('productGtm');
                fillModalElement(selectedValueUrl, $productGtm);
            });
        }
    }
};
