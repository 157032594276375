'use strict';

/**
 * Method to refresh stock in stock
 * @param {string} storeId the store id
 * @param {string} productId the product id
 * @param {string} url url
 */
function getStockInStore(storeId, productId, url) {
    var $stockInStoreBlock = $('.js-stock-in-stock-block');
    var urlId = (url && url.length > 0 ? url : $stockInStoreBlock.data('service-url')) + '?storeId=' + storeId + '&productId=' + encodeURIComponent(productId);

    $('#currentStoreId').val(storeId);
    $('#currentProductId').val(productId);

    $.spinner().start();
    $.ajax({
        url: urlId,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            if (data.error == null && data.renderedTemplate && data.renderedTemplate.length > 0) {
                $stockInStoreBlock.html(data.renderedTemplate);
                $('body').trigger('storedrawer:reinit');
            }
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

module.exports = {
    getStockInStore: getStockInStore
};
