'use strict';

/**
 * This file contains common js functions that can be reused in other components
 */

window.app = {
    device: {
        tablet: Number(
            (getComputedStyle(document.documentElement)
                .getPropertyValue('--breakpoint-md') || '768').replace(/\D/g, '')
        ),
        desktop: Number(
            (getComputedStyle(document.documentElement)
                .getPropertyValue('--breakpoint-lg') || '992').replace(/\D/g, '')
        )
    },
    isDesktop: function () {
        return window.matchMedia('(min-width:' + this.device.desktop + 'px)').matches;
    },
    isTablet: function () {
        return window.matchMedia('(min-width:' + this.device.tablet + 'px)').matches;
    },
    isMobile: function () {
        return window.matchMedia('(max-width:' + parseInt(this.device.tablet - 0.01, 10) + 'px)').matches;
    },
    isIE11: function () {
        // eslint-disable-next-line no-useless-escape
        return navigator.userAgent.match(/Trident.*rv\:11\./);
    }
};
