'use strict';

$(document).ready(function () {
    $('.detail-slider-nav').on('init', function () {
        let $slider = $(this);
        let $videoTiles = $slider.find('.slick-slide:has(video)');

        $videoTiles.toArray().forEach(tile => {
            let slideIndex = $(tile).data('slick-index');
            $slider.find('.slick-dots button').eq(slideIndex).addClass('is-video-dot');
        });
    });

    $('.detail-slider-nav').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        focusOnSelect: true,
        infinite: false,
        arrows: true,
        responsive: [
            {
                breakpoint: 544,
                settings: {
                    arrows: false
                }
            }
        ]
    });
});
