'use strict';

var cart = require('../cart/cart');

var updateMiniCart = true;

module.exports = function () {
    cart();

    $('.minicart').on('count:update', function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            $('.minicart .minicart-quantity').text(count.quantityTotal);
            $('.minicart .minicart-link').attr({
                'aria-label': count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });
        }
    });

    $('.minicart').on('mouseenter focusin touchstart', function () {
        if ($('.search:visible').length === 0 && !$(this).find('.hide-no-link').is(':visible')) {
            return;
        }
        var url = $('.minicart').data('action-url');
        var showMoreUrl = decodeURIComponent(window.atob(url));
        var count = parseInt($('.minicart .minicart-quantity').text(), 10);

        if (count !== 0 && $('.minicart .popover.show').length === 0) {
            if (!updateMiniCart) {
                $('.minicart .popover').addClass('show');
                return;
            }

            $('.minicart .popover').addClass('show');
            $('.minicart .popover').spinner().start();
            $.get(showMoreUrl, function (data) {
                $('.minicart .popover').empty();
                $('.minicart .popover').append(data);
                updateMiniCart = false;
                $.spinner().stop();
            });
        }
    });
    $('body').on('touchstart click', function (e) {
        if ($('.minicart').has(e.target).length <= 0) {
            $('.minicart .popover').removeClass('show');
        }
    });
    $('.minicart').on('mouseleave focusout', function (event) {
        if ((event.type === 'focusout' && $('.minicart').has(event.target).length > 0)
            || (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity'))
            || $('body').hasClass('modal-open')) {
            event.stopPropagation();
            return;
        }
        $('.minicart .popover').removeClass('show');
    });
    $('body').on('change', '.minicart .quantity', function () {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            // eslint-disable-next-line no-restricted-globals
            location.reload();
        }
    });
    $('body').on('product:afterAddToCart', function () {
        updateMiniCart = true;
    });
    $('body').on('cart:update', function () {
        updateMiniCart = true;
    });
    $('document').ready(qtyCircleShowHide);
    $('body').on('product:afterAddToCart', qtyCircleShowHide);
    $('body').on('cart:update', qtyCircleShowHide);
    /**
 * Counts the numer of items in the cart and adapts the display/hide of black circle.
 * No params.
 */
    function qtyCircleShowHide() {
        var count = parseInt($('.minicart .minicart-quantity').first().text(), 10);
        if (count > 0) {
            $('.minicart-quantity.d-none').removeClass('d-none');
        } else if (count === 0) {
            $('.minicart-quantity').addClass('d-none');
        }
    }
};
